
















































import { Vue, Component } from 'vue-property-decorator';
import { MeetingStore } from './store';

@Component({
	components: {
		NavBar: async () => import('./components/NavBar.vue')
	}
})
export default class App extends Vue {
	now: Date = new Date();

	created(): void {
		MeetingStore.init();
	}
}
